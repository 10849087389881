<template>
  <div class="order-wrap">
    <div class="order-title">通知公告</div>
    <div class="order-main">
      <ul class="notice-list">
        <li v-for="(item, index) in noticeList" :key="index">
          <a :href="`/noticeManager/notice/${item.id}`">{{ item.title }}</a>
          <span class="notice-date">{{ item.createTime }}</span>
        </li>
      </ul>
      <div style="text-align: center;" v-if="hasMore">
        <a-button size="small" :loading="loading" @click="onLoadMore">加载更多</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchList } from '@/api/notice'

export default {
  name: 'notice',
  data () {
    return {
      noticeList: [],
      loading: false,
      current: 0,
      hasMore: true
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { current } = this
        this.loading = true
        const data = await fetchList({
          page: current
        })

        this.noticeList = this.noticeList.concat(data.content || [])
        this.loading = false
        this.current = current + 1
        this.hasMore = this.current < data.totalPages
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    onLoadMore () {
      this.fetch()
    }
  }
}
</script>

<style lang="less" scoped>
.notice-list {
  list-style: none;
  padding: 0;

  li {
    line-height: 32px;
    cursor: pointer;
    + li {
      border-top: 1px solid #f8f8f8;
    }
  }

  .notice-date {
    float: right;
  }
}
</style>
